import {useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import {NAVBAR_MOBILE} from './constants';

const useMediaIsDesktop = (query, headerRef) => {
  const isDesktop = useMediaQuery(query);
  useEffect(() => {
    if (!isDesktop) {
      headerRef.current.classList.add(NAVBAR_MOBILE);
    } else if (isDesktop) {
      headerRef.current.classList.remove(NAVBAR_MOBILE);
    }
  }, [isDesktop]);
};
export default useMediaIsDesktop;
