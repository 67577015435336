import React, {useEffect} from 'react';
import {isMobile} from 'react-device-detect';

import {NAVBAR_WHITE, NAVBAR_TRANSPARENT, NAVBAR_HOMEPAGE} from './constants';

const useScrollHandler = (headerRef, position, showFraudBanner, isHomepage) => {
  const scrollHandler = React.useCallback(() => {
    if (window.scrollY > 60) {
      headerRef.current.classList.add(NAVBAR_WHITE);
      headerRef.current.classList.remove(NAVBAR_TRANSPARENT, NAVBAR_HOMEPAGE);
      headerRef.current.style.top = '0px';
    } else {
      if (isHomepage) {
        headerRef.current.classList.add(NAVBAR_HOMEPAGE);
      } else {
        headerRef.current.classList.add(NAVBAR_TRANSPARENT);
      }
      headerRef.current.classList.remove(NAVBAR_WHITE);
      if (localStorage.getItem('b') === null || !localStorage.getItem('b')) {
        headerRef.current.style.top = '0px';
      } else if ((localStorage.getItem('b') === null || !localStorage.getItem('b')) && !isMobile) {
        headerRef.current.style.top = '30px';
      } else if (!isMobile) {
        headerRef.current.style.top = '30px';
      } else if (isMobile) {
        headerRef.current.style.top = '0px';
      }
    }
  }, [headerRef]);

  useEffect(() => {
    if (position === 'sticky') {
      window.addEventListener('scroll', scrollHandler);
    } else {
      headerRef.current.classList.remove(NAVBAR_TRANSPARENT, NAVBAR_HOMEPAGE);
      headerRef.current.classList.add(NAVBAR_WHITE);
    }
    return () => {
      if (position === 'sticky') {
        window.removeEventListener('scroll', scrollHandler);
      }
    };
  }, [position]);
};

export default useScrollHandler;
