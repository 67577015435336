import {useStaticQuery, graphql} from 'gatsby';

const useStaticQueryForLogo = () => {
  const data = useStaticQuery(graphql`
    query HeadingQuery {
      tintash: file(relativePath: {eq: "header-footer/Tintash Logo.png"}) {
        childImageSharp {
          fixed(width: 140) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      tintash_hd: file(relativePath: {eq: "header-footer/Tintash-logo-hd.png"}) {
        childImageSharp {
          fixed(width: 140) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `);
  return data;
};

export default useStaticQueryForLogo;
